@import 'configurable-values';

// Panel Classes
.success-snackbar {
  color: $nuetrals-white;
  background-color: $old-pine;
}
.success-snackbar .mat-simple-snackbar-action {
  color: $nuetrals-white;
}
.error-snackbar {
  color: $nuetrals-white;
  background-color: $pastel-red;
}
.error-snackbar .mat-simple-snackbar-action {
  color: $nuetrals-white;
}
