@import 'configurable-values';

.bg-tux {
  background: $nuetrals-black-pure !important;
}

.bg-white {
  background: $nuetrals-white !important;
}

.bg-tux-darkest {
  background: $background-tux-darkest !important;
}
