@import 'configurable-values';

.lv-light-dialog {
  mat-dialog-container {
    background: $nuetrals-white !important;
    color: $tux !important;

    -ms-overflow-style: none !important; /* IE and Edge */
    scrollbar-width: none !important; /* Firefox */
    ::-webkit-scrollbar {
      display: none !important;
    }

    .mat-dialog-content {
      -ms-overflow-style: none !important; /* IE and Edge */
      scrollbar-width: none !important; /* Firefox */
      ::-webkit-scrollbar {
        display: none !important;
      }
    }
  }
}

.lv-dark-dialog {
  mat-dialog-container {
    background: $tux !important;
    color: $nuetrals-white !important;

    -ms-overflow-style: none !important; /* IE and Edge */
    scrollbar-width: none !important; /* Firefox */
    ::-webkit-scrollbar {
      display: none !important;
    }

    .mat-dialog-content {
      -ms-overflow-style: none !important; /* IE and Edge */
      scrollbar-width: none !important; /* Firefox */
      ::-webkit-scrollbar {
        display: none !important;
      }
    }
  }
}

.mat-dialog-content {
  -ms-overflow-style: none !important; /* IE and Edge */
  scrollbar-width: none !important; /* Firefox */
  ::-webkit-scrollbar {
    display: none !important;
  }
}

// Results Dialog

.lv-dialog-wrapper {
  @include xs {
    padding-left: 10px;
    padding-right: 10px;
    height: 125px;
  }

  @include sm {
    padding-left: 25px;
    padding-right: 25px;
    height: 125px;
  }

  @include md {
    padding-left: 75px;
    padding-right: 75px;
    height: 150px;
  }

  @include lg {
    padding-left: 200px;
    padding-right: 200px;
    height: 175px;
  }

  @include xl {
    padding-left: 256px;
    padding-right: 256px;
    height: 200px;
  }
}

.lv-dialog-title {
  font-size: 35px;
  font-weight: bold;
  color: $nuetrals-white;

  mat-icon {
    color: $nuetrals-white;
    font-size: 56px;
    height: 56px;
    width: 56px;
  }
}

// Offer Type Dialog

.lv-offer-type-title-container {
  margin-bottom: 16px;
}

.lv-offer-type-title {
  font-size: 26px !important;
  font-weight: bold !important;
}

.lv-offer-type-description {
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: center;
}

.lv-offer-type-square {
  margin-top: 30px !important;
}

lv-dispensary-title-container {
  &.mat-dialog-title {
    margin-bottom: 0;
  }
}

.lv-dispensary-title {
  font-size: 26px !important;
  font-weight: bold !important;
}

.lv-dispensary-title-description {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 24px;
  letter-spacing: normal;
  text-align: left;
  margin-bottom: 3rem;
}

.lv-dispensary-list-container {
  // ToDo: Need to make this responsive
  width: 404px;
  height: 454px;
  border-radius: 16px;
  border: solid 2px $nuetrals-grey-5;
  background-color: $nuetrals-white;

  overflow: auto;

  -ms-overflow-style: none !important; /* IE and Edge */
  scrollbar-width: none !important; /* Firefox */
}

.lv-dispensary-list-container-inner {
  height: 100%;
  width: 100%;
  max-height: 425px;
  overflow: auto;

  -ms-overflow-style: none !important; /* IE and Edge */
  scrollbar-width: none !important; /* Firefox */
}

.lv-dispensary-list-items {
  height: 100%;
  width: 100%;
  max-height: 350px;
  overflow-y: auto;
  overflow-x: hidden;
  -ms-overflow-style: none !important; /* IE and Edge */
  scrollbar-width: none !important; /* Firefox */
}

.lv-dispensary-list-items::-webkit-scrollbar {
  display: none !important;
}

.lv-dispensary-list-add-buttons {
  width: 100%;
}

.lv-dispensary-list-buttons {
  max-width: 1600px;
  margin-top: 30px;
  padding-right: 26px;
}

.lv-dispensary-selection-list-buttons {
  max-width: 1600px;
  margin-top: 30px;
  margin-bottom: 10px;
  padding-right: 25rem;
}

.lv-dispensary-list-title {
  width: 100%;
  font-weight: bold;
  font-size: 21px;
  line-height: 27px;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.438rem;
  padding-bottom: 0.5rem;
  height: 3.125rem;
}

.lv-dispensary-list-item {
  width: 100%;
  height: 40px;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  padding-left: 16px;
  padding-right: 16px;
  cursor: pointer;
}

.lv-dispensary-item-selected {
  background-color: $tint-primary-50;
  font-weight: bold;
  width: 100%;
}

.lv-product-list-container {
  // ToDo: Need to make this responsive
  width: 404px;
  height: 454px;
  border-radius: 16px;
  border: solid 2px $nuetrals-grey-5;
  background-color: $nuetrals-white;

  overflow: auto;

  -ms-overflow-style: none !important; /* IE and Edge */
  scrollbar-width: none !important; /* Firefox */
}

.lv-product-list-title {
  margin-top: 0.5rem;
  max-width: 100%;
  font-weight: bold;
  font-size: 21px;
  line-height: 27px;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

// Results dialog

.lv-dialog-result-title {
  font-size: 28px;
  font-weight: bold;
  line-height: 37px;
}

.lv-dialog-result-subtitle {
  font-size: 16px;
  font-weight: bold;
  line-height: 21px;
}

.lv-dialog-result-chart-row {
  width: 90%;
  height: 275px;
  margin-left: auto;
  margin-right: auto;
}

.lv-dialog-result-matrix-row {
  width: 90%;
  height: 275px;

  .lv-matrix-result-answer-label {
    text-transform: capitalize;
    font-size: 14px;
    font-weight: bold;
    text-align: right;
    width: 240px;
    padding-right: 10px;
  }
}

.lv-dialog-rating-section {
  padding-bottom: 30px;

  .lv-rating-average-number {
    font-size: 68px;
    font-weight: bold;
    line-height: 88px;
  }

  .lv-rating-average-text {
    font-size: 21px;
    font-weight: bold;
    line-height: 27px;
  }

  .lv-rating-star-row {
    width: 100%;
    max-width: 160px;

    mat-icon {
      font-size: 27px;
      height: 27px;
      width: 27px;
    }
  }
}

.lv-dialog-result-pinned-title {
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 15px;
}

.lv-dialog-result-pinned-answer {
  width: 250px;
  height: 290px;
  padding: 10px;
  padding-left: 50px;
}

.lv-dialog-result-upload-box {
  margin: 5px;
  width: auto;
  height: 140px;
  border-radius: 16px;

  img {
    border-radius: 16px;
    width: auto;
    height: 140px;
  }

  video {
    border-radius: 16px;
    width: auto;
    height: 140px;
  }
}

.lv-dialog-result-search {
  .lv-filter-results-focus {
    color: $nuetrals-black-pure !important;
    border: 1px solid $nuetrals-black-pure !important;

    mat-icon {
      color: $nuetrals-black-pure !important;
    }
  }
  .lv-filter-results {
    padding: 0.75rem;
    color: $nuetrals-grey-5 !important;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    border: 1px solid $nuetrals-grey-5;
    width: auto;

    input[type='text'] {
      color: $nuetrals-grey-5;
      background: $nuetrals-white;
      border: none;
      outline: none;
      font-size: 17px;
      width: 80%;
    }

    ::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: $nuetrals-grey-5;
      opacity: 1; /* Firefox */
    }

    ::-ms-input-placeholder {
      /* Microsoft Edge */
      color: $nuetrals-grey-5;
    }

    input[type='text']:focus {
      outline: none;
      color: $nuetrals-black-pure;
      border: none;
    }

    input[type='text']:disabled {
      outline: none;
      color: $nuetrals-grey-disabled;
      border: none;
    }
  }

  .lv-search-empty {
    margin-top: 150px;
  }

  .lv-search-empty-title {
    font-size: 1.313rem;
    font-weight: bold;
    text-align: center;
    line-height: 1.688rem;
    color: $nuetrals-black-pure;
  }

  .lv-search-empty-subtitle {
    font-size: 1.125rem;
    font-weight: normal;
    text-align: center;
    line-height: 1.5rem;
    color: $nuetrals-black-pure;
  }
}

.lv-search-response {
  text-wrap: normal;
  width: 250px;
  height: auto;
}

.lv-email-form {
  width: 100%;
}

.lv-add-team-form {
  width: 100%;
  margin: 20px;
  .mat-form-field {
    min-width: 80% !important;
  }
}
