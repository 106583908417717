@import './configurable-values';

.lv-result-card {
  border-radius: 16px;
  width: 100%;
  height: 100%;
  background-color: $nuetrals-white;
  color: $nuetrals-black-pure;
}

.lv-short-answer-card {
  border-radius: 16px;
  border: 1px solid #C8C8C8;
  width: 100%;
  height: 100%;
  padding: 19px;
  background-color: $nuetrals-white;
  color: $nuetrals-black-pure;
  cursor: pointer;

  .lv-short-answer-title {
    font-size: 19px;
    font-weight: bold;
    line-height: 25px;
  }

  .lv-short-answer-subtitle {
    font-size: 14px;
    font-weight: normal;
    line-height: 18px;
    color: #343535;
  }

  .lv-short-answer-content {
    width: 100%;
    height: 100%;
  }

  .lv-short-answer-text-box {
    max-width: 230px;
    width: 100%;
    height: 125px !important;

    .lv-short-answer-text {
      height: 100px;
      font-size: 1rem;
      font-weight: normal;
      font-stretch: normal;
      font-style: italic;
      line-height: 1.31;
      letter-spacing: normal;
      word-wrap: break-word;
      color: $nuetrals-black-pure;
    }

    .lv-short-answer-user {
      text-transform: capitalize;
      margin-top: 10px;
      font-size: 14px;
      font-weight: bold;
      line-height: 1.125rem;
      color: $nuetrals-black-pure;
    }

    .lv-short-answer-dispensary {
      text-transform: capitalize;
      font-size: 16px;
      font-weight: normal;
      line-height: 21px;
    }
  }

  .lv-short-answer-email-container {
    height: 175px;
    width: 100%;
    color: $nuetrals-black-pure;
    font-family: AvenirNext, sans-serif;

    .lv-short-answer-results-nbr {
      font-size: 4.25rem;
      line-height: 5.5rem;
      font-weight: bold;
    }

    .lv-short-answer-respondents-nbr {
      font-size: 1.125rem;
      line-height: 1.5rem;
      font-weight: bold;
    }

    .lv-short-answer-email-subline {
      text-align: center;
      font-size: 1.313rem;
      line-height: 1.688rem;
      font-weight: bold;
    }
  }

  .lv-short-answer-button-box {
    height: 125px;
  }
}

.lv-optional-label {
  width: 100%;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 0 !important;
  font-size: 1rem;
  line-height: 1.313rem;
  letter-spacing: 0;
  color: $nuetrals-black-pure;
}

.lv-rating-card {
  border-radius: 16px;
  border: 1px solid #C8C8C8;
  width: 100%;
  height: 100%;
  padding: 19px;
  background-color: $nuetrals-white;
  color: $nuetrals-black-pure;
  cursor: pointer;

  .lv-rating-title {
    font-size: 19px;
    font-weight: bold;
    line-height: 25px;
    min-height: 70px;
  }

  .lv-rating-title-medium {
    font-size: 20px;
    font-weight: bold;
    line-height: 25px;
    min-height: 70px;
  }

  .lv-rating-title-small {
    font-size: 19px;
    font-weight: bold;
    line-height: 25px;
  }

  .lv-rating-average-number {
    font-size: 81px;
    font-weight: bold;
    line-height: 105px;
  }

  .lv-rating-average-text {
    font-size: 25px;
    font-weight: bold;
    line-height: 32px;
  }

  .lv-rating-star-row {
    width: 100%;
    max-width: 160px;

    mat-icon {
      font-size: 28px;
      height: 32px;
      width: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .lv-rating-content {
    width: 100%;
  }
}

.lv-ranking-card {
  border-radius: 16px;
  border: 1px solid #C8C8C8;
  width: 100%;
  height: 100%;
  padding: 19px;
  background-color: $nuetrals-white;
  color: $nuetrals-black-pure;
  cursor: pointer;

  .lv-ranking-title {
    font-size: 19px;
    font-weight: bold;
    line-height: 25px;
    margin-bottom: 10px;
  }

  .lv-ranking-rows {
    width: 95%;
    height: 100%;
    max-height: 175px;
    text-transform: capitalize;
    font-size: 14px !important;
    font-weight: bold !important;
    overflow-y: hidden;
    overflow-x: auto;
  }

  .lv-ranking-chart {
    text-transform: capitalize;
    font-size: 14px !important;
    font-weight: bold !important;
  }
}

.lv-single-select-card {
  border-radius: 16px;
  border: 1px solid #C8C8C8;
  width: 100%;
  height: 100%;
  padding: 19px;
  background-color: $nuetrals-white;
  color: $nuetrals-black-pure;
  cursor: pointer;

  .lv-single-select-title {
    text-align: left;
    font-size: 19px;
    font-style: normal;
    font-weight: 700;
    line-height: 25px;
    margin-bottom: 10px;
  }

  .lv-single-select-horizontal-chart {
    text-transform: capitalize;
    font-size: 14px !important;
    font-weight: bold !important;
    text-align: center;
    width: 90%;
    padding-left: 10%;
    height: 150px;
    .ngx-charts-bar-horizontal {
      padding-bottom: 100%;
      position: absolute;
    }
  }

  .lv-single-select-pie-chart {
    text-transform: capitalize;
    font-size: 14px !important;
    font-weight: bold !important;
    width: 90%;
    height: 100%;
  }
}

.lv-multi-select-card {
  border-radius: 16px;
  border: 1px solid #C8C8C8;
  width: 100%;
  height: 100%;
  padding: 19px;
  background-color: $nuetrals-white;
  color: $nuetrals-black-pure;
  cursor: pointer;

  .lv-multi-select-title {
    font-size: 19px;
    font-weight: 700;
    line-height: 25px;
    margin-bottom: 10px;
  }

  .lv-multi-select-vertical-chart {
    text-transform: capitalize;
    font-size: 14px !important;
    font-weight: bold !important;
    text-align: center;
    width: 90%;
    padding-left: 10%;
    height: auto;
    max-height: 175px;
  }
}

.lv-matrix-card {
  border-radius: 16px;
  width: 100%;
  height: 100%;
  border: 1px solid #C8C8C8;
  padding: 19px;
  background-color: $nuetrals-white;
  color: $nuetrals-black-pure;
  cursor: pointer;

  .lv-matrix-title {
    font-size: 19px;
    font-weight: 700;
    line-height: 25px;
  }

  .lv-matrix-subtitle {
    margin-bottom: 32px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
  }

  .lv-matrix-content {
    width: 100%;
    overflow-y: auto;
  }

  .lv-matrix-answer-row {
    width: 100%;
    &:not(:last-child) {
      padding-bottom: 10px;
    }
    .lv-matrix-answer-label {
      text-transform: capitalize;
      font-size: 16px;
      font-weight: bold;
      text-align: right;
      width: 60%;
      min-width: 120px;
    }

    .lv-matrix-star-row {
      width: 100%;
      max-width: 160px;
      padding-left: 7px;

      mat-icon {
        font-size: 26px;
        height: 26px;
        width: 26px;
      }
    }
  }
}
.lv-file-upload-card {
  border-radius: 16px;
  width: 100%;
  height: 100%;
  border: 1px solid #C8C8C8;
  padding: 19px;
  background-color: $nuetrals-white;
  color: $nuetrals-black-pure;
  cursor: pointer;

  .lv-file-upload-title {
    font-size: 19px;
    font-weight: 700;
    line-height: 25px;
  }
  .lv-file-upload-subtitle {
    font-size: 16px;
    font-weight: normal;
    line-height: 21px;
  }

  .lv-file-upload-content {
    width: 100%;
    height: 100%;
  }

  .lv-file-upload-image-box {
    max-width: 125px;
    width: 100%;
    height: 100%;
    max-height: 125px;
    border-radius: 16px;

    img {
      border-radius: 16px;
      width: auto;
      height: 100%;
      max-width: 125px;
      max-height: 160px;
    }
  }

  .lv-file-upload-button-box {
    height: 125px;
  }
}
.lv-card-correct-legend {
  font-family: 'Avenir Next';
  font-weight: 700;
  font-size: 16px;
  line-height: normal;
  color: $nuetrals-black-pure;
  margin-bottom: 0px;

  &-color {
    margin-right: 10px;
    width: 20px;
    height: 20px;
    border-radius: 2px;
    background-color: $tint-primary;
  }
}
