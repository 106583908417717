@import '../global/configurable-values';
@import '../global/mixins';

.lv-offer-edit-container {
  width: 100%;
  max-width: 1600px;
  margin: 0;
  padding-top: 50px;
  margin: auto;
}

.lv-offer-edit-form {
  .mat-form-field-appearance-legacy {
    .mat-form-field-infix {
      padding: 0.7em 0;
    }
  }

  padding-bottom: 30px;
}

.lv-upload-container {
  width: 328px;
  height: 184px;
  padding: 31px 43px 33px;
  border-radius: 16px;
  border: solid 3px $tux;
  border-style: dashed;
  margin-bottom: 32px;

  .lv-upload-icon {
    width: 64px;
    height: 64px;
    object-fit: contain;
    margin-left: auto;
    margin-right: auto;
  }

  .lv-upload-text {
    width: 242px;
    font-family: AvenirNext, sans-serif;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
  }
}

.lv-offer-jobs {
  ::ng-deep .mat-select-value {
    color: $nuetrals-white !important;
  }
}
