@import './configurable-values';
@import './configurable-values';

.lv-checkbox {
  .mat-checkbox-frame {
    border-color: $tint-primary;
    background-color: inherit;
  }

  label {
    font-weight: bold;
  }
}

.lv-checkbox:disabled {
  .mat-checkbox-frame {
    border-color: $dark-grey;
    background-color: $tint-primary;
  }

  label {
    font-weight: bold;
    color: $nuetrals-black-pure;
  }
}

.lv-checkbox-large {
  margin-bottom: 25px;
  display: flex;
  align-items: center;
  justify-content: center;

  .mat-checkbox-frame {
    border-color: $tint-primary;
    background-color: inherit;
  }

  .mat-checkbox-inner-container {
    width: 24px;
    height: 24px;
  }

  label {
    font-weight: bold;
    font-size: 18px;
    color: $nuetrals-black-pure;
  }
}

.lv-checkbox-large:disabled {
  .mat-checkbox-frame {
    border-color: $dark-grey;
    background-color: $tint-primary;
  }

  .mat-checkbox-inner-container {
    width: 24px;
    height: 24px;
  }

  .mat-checkbox-label {
    font-weight: bold;
    color: $nuetrals-black-pure;
    font-size: 18px;
  }
}

.mat-checkbox-disabled .mat-checkbox-label {
  color: $tux;
}

.lv-disabled-checkbox {
  .mat-checkbox-frame {
    border-color: $dark-grey;
    background-color: $tint-primary;
  }

  label {
    font-weight: bold;
    color: $nuetrals-black-pure;
  }
}

.lv-checkbox-normal {
  .mat-checkbox-frame {
    border-color: $tint-primary;
    background-color: inherit;
  }

  label {
    font-weight: normal;
    size: 1rem;
    line-height: 1.313rem;
    color: $nuetrals-black-pure;
    text-transform: capitalize;
  }
}

.lv-list-item {
  .mat-list-text {
    font-weight: normal;
    size: 1rem;
    line-height: 1.313rem;
    color: $nuetrals-black-pure;
    text-transform: capitalize;
  }

  .mat-pseudo-checkbox.mat-pseudo-checkbox-checked {
    border-color: $tint-primary;
    background-color: inherit;
    background: $tint-primary !important;
  }

  .mat-pseudo-checkbox {
    border-color: $tint-primary;
    background-color: inherit;
  }
}

.mat-checkbox-checkmark-path {
  stroke: $nuetrals-white !important;
}

