body {
  font-family: AvenirNext, sans-serif !important;
}

// Import main Angular Material Theme
@import 'assets/scss/theme/leaf-connect.theme';

// Import Material Mixins
@import 'assets/scss/theme/mixins';

// Global
@import 'assets/scss/global/global';

// Components
@import 'assets/scss/components/components';
